import React, { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { loadLanguages, loadLocales } from "../../api/locale.api";
import LocaleComponent from "./locale";
import reducer, {
  initialState,
  prepLocaleData,
} from "../../reducers/locale.reducer";
import { withAuthorization } from "../../firebase/with-authorization";
import { IUser } from "../../reducers/users.reducer";
import LocaleCreateModal from "./locale-create-modal";

interface IProps {
  authUser: IUser;
}

function GlobalsComponent(props: IProps) {
  const { authUser } = props;

  const [data, dispatch] = useReducer(reducer, initialState);
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const openModal = () => toggleOpen(true);
  const closeModal = () => toggleOpen(false);

  const params = useParams();
  const collection = params.collection as string;

  useEffect(() => {
    async function load() {
      const [localeData, allLanguages]: [any, any] = await Promise.all([
        loadLocales(collection),
        loadLanguages(),
      ]);
      const languages = Object.keys(allLanguages) || [];
      dispatch(
        prepLocaleData({
          ...localeData,
          languages,
        })
      );
    }

    load();
  }, [collection]);

  return (
    <>
      <LocaleComponent
        authUser={authUser}
        data={data}
        dispatch={dispatch}
        collection={collection}
        openModal={openModal}
      />
      <LocaleCreateModal
        handleClose={closeModal}
        isOpen={isOpen}
        collection={collection}
        data={data}
        dispatch={dispatch}
      />
    </>
  );
}

const authCondition = (authUser: any) => !!authUser;

export default withAuthorization(authCondition)(GlobalsComponent);
