import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from '../pages/dashboard/dashboard.page';
import UsersComponent from "../pages/users/users.page";
import Theme from './theme';
import OverridesComponent from '../pages/overrides/overrides.page';
import GlobalsComponent from '../pages/components/globals';

export const routes = {
  home: '/',
  project: '/project/:collection',
  overrides: '/overrides/:collection',
  theme: '/theme/:collection',
  users: '/users'
}

export default () => (
  <Routes>
    <Route path={routes.project} element={<GlobalsComponent/>} />
    <Route path={routes.overrides} element={<OverridesComponent/>} />
    <Route path={routes.theme} element={<Theme/>} />
    <Route path={routes.users} element={<UsersComponent/>} />
    <Route path={routes.home} element={<Dashboard/>} />
  </Routes>
)
