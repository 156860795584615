import React, {useEffect, useReducer, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from '@material-ui/core/Fab';
import {withAuthorization} from "../firebase/with-authorization";
import {IUser} from "../reducers/users.reducer";
import Snackbar from "@material-ui/core/Snackbar";
import ReactJson, {InteractionProps} from 'react-json-view'
import {loadThemeData, updateThemeData} from "../api/theme.api";
import reducer, {initialState, setThemeData, trackChanges} from "../reducers/theme.reducer";

interface IProps {
  authUser: IUser;
}

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    position: 'fixed',
    marginRight: theme.spacing(1),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  fab: {
    marginRight: theme.spacing(1),
  },
}));

function ThemeComponent(props: IProps) {
  const {authUser} = props;
  const canEdit = authUser?.roles?.stylist;
  const isAdmin = authUser?.roles?.admin;

  const classes = useStyles();

  const params = useParams();

  const [data, dispatch] = useReducer(reducer, initialState);
  const {theme, changes} = data;

  const [snackBar, toggleSnackbar] = useState(false);
  const collection = params.collection as string;

  useEffect(() => {
    async function load() {
      const themeData = await loadThemeData(collection);
      dispatch(setThemeData(themeData));
    }

    load();
  }, [collection]);

  const onSave = async () => {
    await updateThemeData(collection, changes);
    toggleSnackbar(true);
  };

  const onPromote = async (stage: string) => {
    await updateThemeData(collection, changes, stage);
    toggleSnackbar(true);
  }

  const themeEdit = (e: InteractionProps) => {
    const flatKey = [...e.namespace, e.name].reduce((a, b) => `${a}.${b}`);
    dispatch(trackChanges({[`${flatKey}`]: e.new_value}));
  }

  return (
    <Grid container>
      <ReactJson
        src={theme}
        theme="monokai"
        style={{width: '100%'}}
        onEdit={canEdit && themeEdit}
      />

      <div className={classes.extendedIcon}>
        {canEdit &&
        <Fab variant="extended" onClick={() => onSave()} className={classes.fab}>
            Save Changes
        </Fab>
        }
        {isAdmin &&
        <Fab variant="extended" onClick={() => onPromote('uat')} className={classes.fab}>
            To UAT
        </Fab>
        }
        {isAdmin &&
        <Fab variant="extended" onClick={() => onPromote('prod')}>
            To Production
        </Fab>
        }
      </div>
      <Snackbar
        open={snackBar}
        autoHideDuration={6000}
        onClose={() => toggleSnackbar(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        message="Action Completed"
      />
    </Grid>
  );
}

const authCondition = (authUser: any) => !!authUser;

export default withAuthorization(authCondition)(ThemeComponent);
