import makeStyles from "@material-ui/core/styles/makeStyles";

export const modalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    fab: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    formControl: {
        minWidth: 150,
    },
    header: {
        marginBottom: theme.spacing(2),
    },
    row: {
        marginBottom: theme.spacing(2),
    },
}));