export interface IFirebaseCollection {
  name: string;
  collection: string;
  overrides: boolean
}

export const firebaseCollection: IFirebaseCollection[] = [{
  name: 'CMS',
  collection: 'config-cms',
  overrides: true,
}, {
  name: 'Config Lib',
  collection: 'config-lib',
  overrides: false,
}, {
  name: 'Config UI',
  collection: 'config-ui',
  overrides: false
}, {
  name: 'Member API',
  collection: 'member-api',
  overrides: false,
}, {
  name: 'Member UI',
  collection: 'member-ui',
  overrides: false,
}];
