import { ILocale } from "../interfaces/locale.interface";

export function modelLocaleData(payload: ILocale) {
  const keySet = new Set<string>();
  Object.keys(payload).forEach((localeCode) =>
    Object.keys(payload[localeCode]).forEach((key) => keySet.add(key))
  );

  const keys = Array.from(keySet);
  const filters = keys.map((k) =>
    Object.keys(payload).reduce(
      (filterText, localeCode) => filterText + " " + payload[localeCode][k],
      `${k}`
    )
  );

  return { keys, locales: payload, filters };
}

export function modelExportData(payload: ILocale) {
  const keySet = new Set<string>();
  Object.keys(payload).forEach((localeCode) =>
    Object.keys(payload[localeCode]).forEach((key) => keySet.add(key))
  );

  const keys = Array.from(keySet);

  return keys.map((k) => {
    return {
      key: k,
      ...Object.keys(payload).reduce((result, localeCode) => {
        result[localeCode] = payload[localeCode][k];
        return result;
      }, {} as any),
    };
  });
}

function createLocaleData(locales: string[], keys: string[], source: any) {
  const locale: any = {};

  locales.forEach((localeCode) => {
    locale[localeCode] = [...keys].reduce(
      (result, key) =>
        Object.assign(result, { [key]: source[localeCode][key] }),
      {}
    );
  });

  return locale;
}

export function localeFilter(source: any, filters: string[], value: string) {
  const keys = filters
    .filter((s) => s.toLowerCase().includes(value.toLowerCase()))
    .map((i) => i.split(" ")[0]);

  const localeData: any = {
    locale: createLocaleData(Object.keys(source), keys, source),
    keys,
  };

  return localeData;
}


export function filterEmptyLocaleValues(locale: any, selectedLanguages: string[]) {
  const lookup = new Set<string>();
  Object.keys(locale).forEach((localeCode) => {
    Object.keys(locale[localeCode]).forEach((key) => 
    lookup.add(key)
    )
  })
  
  const keys = new Array<string>();
  Object.keys(locale).forEach(localeCode => {
    if(selectedLanguages.indexOf(localeCode) >= 0) {
      lookup.forEach((path) => {
        const setValue = locale[localeCode][path]
        if(setValue === undefined || setValue.trim().length === 0) {
          keys.push(path)
        }
      })
    }
  })

  return {
    locale: {
      en: [...keys].reduce( (res, key) => Object.assign(res, { [key]: locale["en"][key] }), {} ),
      es: [...keys].reduce( (res, key) => Object.assign(res, { [key]: locale["es"][key] }), {} ),
      pt: [...keys].reduce( (res, key) => Object.assign(res, { [key]: locale["pt"][key] }), {} ),
      nl: [...keys].reduce( (res, key) => Object.assign(res, { [key]: locale["nl"][key] }), {} ),
    },
    keys,
  }
}