import React, {useEffect, useReducer} from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withAuthorization} from "../../firebase/with-authorization";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import reducer, {
  initialState,
  IUser,
  removeDeletedUser,
  setNewUser,
  setUser,
  setUsers,
  setUserUpdated,
  updateRoles,
  updateUser
} from "../../reducers/users.reducer";
import {FormControlLabel} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {addNewFirebaseUser, deleteFirebaseUser, getFirebaseUsers, updateFirebaseUser} from "../../api/users.api";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  info: {
    marginBottom: '10px',
  }
}));

interface IProps {
  authUser: IUser,
}

function UsersComponent(props: IProps) {
  const {authUser} = props;

  const [data, dispatch] = useReducer(reducer, initialState);
  const {user, users, loading} = data;
  const classes = useStyles();

  useEffect(() => {
    const load = async () => {
      const users = await getFirebaseUsers();
      dispatch(setUsers(users));
    }

    load();
  }, [])

  const handleChange = (event: React.ChangeEvent) => {
    event.preventDefault();
    //@ts-ignore
    const {target: {name, value, checked, type}} = event;

    type === 'checkbox' ?
      dispatch(updateRoles({name, checked})) :
      dispatch(updateUser({name, value}));
  }

  const handleAdd = async () => {
    const id = await addNewFirebaseUser(data.user);
    dispatch(setNewUser({
      id,
      ...data.user,
    }));
  };

  const handleUpdate = async () => {
    await updateFirebaseUser(data.user);
    dispatch(setUserUpdated(data.user));
  }

  const handleDelete = async (user: IUser) => {
    await deleteFirebaseUser(user);
    dispatch(removeDeletedUser(user.id));
  }

  const handleEdit = (user: IUser) => dispatch(setUser(user))

  const userItem = (usr: IUser) => {
    const isMe = usr.email === authUser.email;

    return (
      <Grid key={usr.id} container spacing={4} alignItems="center">
        <Grid item xs={6}>
          <Typography>
            {usr.email} &nbsp;
          </Typography>
          <Typography variant="caption">
            {usr.roles.admin && 'Admin |'}
            {usr.roles.editor && ' Editor |'}
            {usr.roles.creator && ' Creator |'}
            {usr.roles.stylist && ' Stylist |'}
            {usr.roles.overrideCreator && ' Create Overrides'}
          </Typography>
        </Grid>
        {!isMe && <Grid item xs={2}>
            <Grid container spacing={4} justifyContent="space-between">
                <Grid item><EditIcon onClick={() => handleEdit(usr)}/></Grid>
                <Grid item><DeleteIcon onClick={() => handleDelete(usr)}/></Grid>
            </Grid>
        </Grid>}
      </Grid>
    )
  }

  return (
    <Grid container direction="row" className={classes.container}>
      <Grid item xs={6}>
        <Typography variant="body1" className={classes.info}>
          {loading ? 'Fetching users...' : 'You are always excluded from the list'}
        </Typography>
        {authUser && users.map(userItem)}
      </Grid>
      {authUser?.roles?.admin &&
      <Grid item xs={6}>
          <Grid container direction="column">
              <Grid item xs={6}>
                  <TextField
                      name="email"
                      value={user.email}
                      onChange={handleChange}
                      fullWidth
                  />
              </Grid>
              <Grid item>
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.roles.admin}
                          onChange={handleChange}
                          name="admin"
                          color="primary"
                        />
                      }
                      label="Admin"
                  />
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.roles.editor}
                          onChange={handleChange}
                          name="editor"
                          color="primary"
                        />
                      }
                      label="Editor"
                  />
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.roles.creator}
                          onChange={handleChange}
                          name="creator"
                          color="primary"
                        />
                      }
                      label="Creator"
                  />
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.roles.overrideCreator}
                          onChange={handleChange}
                          name="overrideCreator"
                          color="primary"
                        />
                      }
                      label="Create Overrides"
                  />
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={user.roles.stylist}
                          onChange={handleChange}
                          name="stylist"
                          color="primary"
                        />
                      }
                      label="Stylist"
                  />
              </Grid>
              <Grid item>
                  <Button variant="contained" color="primary" onClick={user.id ? handleUpdate : handleAdd}>
                    {user.id ? 'Update' : 'Add'}
                  </Button>
              </Grid>
          </Grid>
      </Grid>
      }
    </Grid>
  );
}

const authCondition = (authUser: any) => !!authUser;

export default withAuthorization(authCondition)(UsersComponent);
