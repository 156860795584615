import React from 'react';
import AppHeader from './components/header';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {useMediaQuery, CssBaseline} from "@material-ui/core";
import {ViewportProvider} from './context/use-viewport';
import DrawNavigation from "./components/drawer";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import Routes from "./core/routes";

function App() {
  const [open, setOpen] = React.useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <main>
          <AppHeader
            handleDrawerOpen={handleDrawerOpen}
          />
          <DrawNavigation
            open={open}
            handleDrawerClose={handleDrawerClose}
          />
          <ViewportProvider>
            <div>
              <Routes />
            </div>
          </ViewportProvider>
        </main>
      </Router>
    </ThemeProvider>
  );
}

export default App;
