import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import TranslateIcon from '@material-ui/icons/Translate'
import IconButton from '@material-ui/core/IconButton'
import { TextField } from '@material-ui/core'

interface IProps {
  language: string;
  localeKey: string;
  locale: {
    [k: string]: string;
  };
  canEdit: boolean;
  onBlur: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    localeKey: string
  ) => void;
  handleTranslate: (text: string, localeKey: string, lang: string) => void;
}

const LocaleLanguageCell: FunctionComponent<IProps> = (props) => {
  const {language, localeKey, locale, canEdit, onBlur, handleTranslate} = props;

  const showTranslateButton = language !== 'en' && canEdit;

  return (
    <Grid
      direction="row"
      container
      alignItems="center"
      item xs={3}
    >
      <Grid item xs={showTranslateButton ? 11 : 12}>   
        <TextField
          id={`${language}-value-${localeKey}`}
          name={language}
          defaultValue={locale[language]}
          onBlur={e => onBlur(e, localeKey)}
          disabled={!canEdit}
          minRows={2}
          multiline
          fullWidth
        />
      </Grid>
        
      {showTranslateButton &&
        <Grid item xs={1}>
          <IconButton onClick={() => handleTranslate(locale['en'], localeKey, language)} >
            <TranslateIcon/>
          </IconButton>
        </Grid>
      }
    </Grid>
  )
}

export default LocaleLanguageCell
