import React from "react";
import { Grid, TextField } from "@material-ui/core";
import LocaleLanguageCell from "./locale-language-cell";

interface IProps {
  localeKey: string;
  locale: {
    [k: string]: string;
  };
  onBlur: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    localeKey: string
  ) => void;
  canEdit: boolean;
  selectedLanguages: string[];
  handleTranslate: (text: string, localeKey: string, lang: string) => void;
}

export default function LocaleRow(props: IProps) {
  const { localeKey, locale, onBlur, canEdit, selectedLanguages, handleTranslate } = props;

  return (
    <Grid container spacing={4} alignItems="flex-end">
      <Grid item xs={3} container>
          <TextField
            id={`locale-key-${localeKey}`}
            value={localeKey}
            fullWidth
            disabled
          />
      </Grid>
      {selectedLanguages.map((sl: string) => (
        <LocaleLanguageCell
          key={sl}
          language={sl}
          localeKey={localeKey}
          locale={locale}
          canEdit={canEdit}
          onBlur={onBlur}
          handleTranslate={handleTranslate}
        />
      ))}
    </Grid>
  )
}
