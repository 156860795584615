import React, {useEffect, useState} from 'react';
import useTheme from "@material-ui/core/styles/useTheme";
import IconButton from '@material-ui/core/IconButton/IconButton';
import Drawer from '@material-ui/core/Drawer/Drawer';
import Divider from '@material-ui/core/Divider/Divider';
import List from '@material-ui/core/List';
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {firebaseCollection, IFirebaseCollection} from "../api/constants";
import {useNavigate} from 'react-router-dom';
import {withAuthentication} from "../firebase/with-authentication";
import {routes} from "../core/routes";
import pkg from '../../package.json';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

interface IProps {
  open: boolean;
  handleDrawerClose: () => void;
  authUser: any;
}

function DrawNavigation(props: IProps) {
  const {open, handleDrawerClose, authUser} = props;
  
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  
  useEffect(() => {
    setLoggedIn(!!authUser);
  }, [authUser]);
  
  const projectNavigation = (collection: string) => {
    handleDrawerClose();
    return navigate(`/project/${collection}`);
  }
  
  const overrideNavigation = (collection: string) => {
    handleDrawerClose();
    return navigate(`/overrides/${collection}`);
  }
  
  const handleNavigation = (path: string) => {
    handleDrawerClose();
    return navigate(path);
  };
  
  // console.log(pkg.version);
  
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
        </IconButton>
      </div>
      <Divider/>
      <List>
        <ListItem button onClick={() => handleNavigation(routes.home)}>
          <ListItemText primary="Home"/>
        </ListItem>
      </List>
      {loggedIn &&
      <>
          <Divider/>
          <List>
            {firebaseCollection.map((project: IFirebaseCollection) => {
              return (
                <div key={project.name}>
                  <ListItem button key={project.collection} onClick={() => projectNavigation(project.collection)}>
                    <ListItemText primary={project.name}/>
                  </ListItem>
                  {project.overrides &&
                  <ListItem
                      button
                      key={`${project.collection}-overrides`}
                      onClick={() => overrideNavigation(`${project.collection}-overrides`)}
                      className={classes.nested}
                  >
                      <ListItemText primary="Overrides"/>
                  </ListItem>
                  }
                </div>
              );
            })}
          </List>
          <Divider/>
          <List>
              <ListItem button onClick={() => handleNavigation(routes.users)}>
                  <ListItemText primary="Users"/>
              </ListItem>
          </List>
          <Divider/>
      </>
      }
      <List>
        <ListItem><small>Version: {pkg.version}</small></ListItem>
      </List>
    </Drawer>
  );
}

export default withAuthentication(DrawNavigation);
