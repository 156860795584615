import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {auth} from "../firebase";
import {useNavigate} from 'react-router-dom';
import {routes} from "../core/routes";
import {withAuthentication} from "../firebase/with-authentication";
import pkg from '../../package.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface IProps {
  handleDrawerOpen: () => void;
  authUser: any;
}


function AppHeader(props: IProps) {
  const {handleDrawerOpen, authUser} = props;
  
  const classes = useStyles();
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  
  useEffect(() => {
    setLoggedIn(!!authUser);
  }, [authUser]);
  
  const login = async () => await auth.googleSignIn();
  const logout = async () => {
    await auth.googleSignOut();
    return navigate(routes.home, { replace: true });
  };
  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Stream (App deployment streamline executive management toolbox) {pkg.version}
          </Typography>
          <Button color="inherit" onClick={loggedIn ? logout : login}>{loggedIn ? 'Logout' : 'Login'}</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withAuthentication(AppHeader);
