import {createContext} from "react";
import * as firebase from "firebase/app";

const firebaseUser: firebase.UserInfo = {
  uid: '',
  displayName: null,
  email: null,
  phoneNumber: null,
  photoURL: null,
  providerId: ''

};
export const AuthUserContext = createContext(firebaseUser);
