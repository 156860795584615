interface IThemeReducer {
  theme: Object;
  changes: Object;
}

export const initialState: IThemeReducer = {
  theme: {},
  changes: {},
}

const SET_THEME_DATA = 'SET_THEME_DATA';
const TRACK_CHANGES = 'TRACK_CHANGES';
export const setThemeData = (data: any) => ({type: SET_THEME_DATA, data});
export const trackChanges = (data: any) => ({type: TRACK_CHANGES, data});

export default function themeReducer(state = initialState, action: {type: string; data: any}): IThemeReducer {
  const {type, data} = action;

  switch (type) {
    case SET_THEME_DATA:
      return {
        ...state,
        theme: data,
      }

    case TRACK_CHANGES: {
      return {
        ...state,
        changes: {
          ...state.changes,
          ...data,
        },
      };
    }
    default:
      return state;
  }
};