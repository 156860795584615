import Fab from "@material-ui/core/Fab/Fab";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import {IUser} from "../../reducers/users.reducer";
import {useLocation} from "react-router-dom";

interface IProps {
  onSave: () => void;
  onExport: () => void;
  onPromote: (stage: string) => void;
  onToggle: () => void;
  authUser: IUser;
}

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    position: 'absolute',
    marginRight: theme.spacing(1),
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  fab: {
    marginRight: theme.spacing(1),
  },
}));

export default function LocaleActions(props: IProps) {
  const {authUser, onSave, onPromote, onToggle, onExport} = props;
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const classes = useStyles();

  const canEdit = authUser?.roles?.editor;
  const canCreate = authUser?.roles?.creator;
  const isAdmin = authUser?.roles?.admin;
  const canOverride = authUser?.roles?.overrideCreator && location.pathname.includes('/overrides/');

  return (
    <div className={classes.extendedIcon}>
      <Fab variant="extended" aria-controls="actions-menu" aria-haspopup="true" onClick={handleClick}>
        Actions
      </Fab>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {canEdit && <MenuItem onClick={() => onSave()}>Save Changes</MenuItem>}
        {(canCreate || canOverride) && <MenuItem onClick={() => onToggle()}>Create entry</MenuItem>}
        {isAdmin && <MenuItem onClick={() => onPromote('uat')}>To UAT</MenuItem>}
        {isAdmin && <MenuItem onClick={() => onPromote('beta')}>To Beta</MenuItem>}
        {isAdmin && <MenuItem onClick={() => onPromote('prod')}>To Prod</MenuItem>}
        {isAdmin && <MenuItem onClick={() => onExport()}>Export CSV</MenuItem>}
      </Menu>
    </div>
  );
}
