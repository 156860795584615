import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD_DiABsR6cVyAv9lovHONF_yXMmqhH43Q",
  authDomain: "wyzetalk-config.firebaseapp.com",
  databaseURL: "https://wyzetalk-config.firebaseio.com",
  projectId: "wyzetalk-config",
  storageBucket: "wyzetalk-config.appspot.com",
  messagingSenderId: "415727209203",
  appId: "1:415727209203:web:5b5eb6c0600f05ffb84586"
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const auth = firebase.auth();
export const db = firebase.database();
