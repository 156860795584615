import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {
  IState,
  newLocaleSaved,
  setCreateData,
} from "../../reducers/locale.reducer";
import { addLocale, translateLocales } from "../../api/locale.api";
import { modalStyles } from "../../styles/modal.styles";

interface IProps {
  isOpen: boolean;
  collection: string;
  handleClose: () => void;
  activeEntryCode?: string;
  data: IState;
  dispatch: React.Dispatch<{
    type: String;
    data: any;
  }>;
}

function moveEnFirst(keys: string[]) {
  const enIndex = keys.indexOf("en");
  if (enIndex <= 0) return keys;
  else {
    keys.splice(enIndex, 1);
    keys.unshift("en");
  }
}

export default function LocaleCreateModal(props: IProps) {
  const { collection, activeEntryCode, data, dispatch } = props;

  const classes = modalStyles();
  const [valid, toggleValid] = useState(false);

  const { isOpen, handleClose } = props;
  const { newLocale, sourceLocale } = data;
  const localeKeys = Object.keys(sourceLocale);
  moveEnFirst(localeKeys);

  const handleChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    const { name, value } = event.target;
    dispatch(setCreateData({ name, value }));
  };

  const onSave = async (close: boolean = false) => {
    await addLocale(collection, newLocale);
    dispatch(newLocaleSaved(newLocale));
    if (close) handleClose();
  };

  const handleBlur = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    const translations = await translateLocales(event.target.value, localeKeys);

    translations.forEach((translation) => {
      if (translation) {
        const { name, value } = translation;
        dispatch(setCreateData({ name, value }));
      }
    });
  };

  useEffect(() => {
    toggleValid(newLocale.key.split(".").length > 1);
  }, [newLocale]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={classes.header}
          >
            <Grid item>
              <h2 id="transition-modal-title">Create new locale entry</h2>
            </Grid>
            <Grid item>
              <h2 id="transition-modal-title">{activeEntryCode}</h2>
            </Grid>
          </Grid>

          <Grid item className={classes.row}>
            <TextField
              name="key"
              value={newLocale.key}
              onChange={handleChange}
              placeholder="Locale Path"
              fullWidth
            />
          </Grid>

          <Grid container spacing={4}>
            {localeKeys.map((localeKey, index) => {
              if (index % 2 === 0) {
                const nextLocaleKey = localeKeys[index + 1];
                return (
                  <Grid item key={index}>
                    <Grid item className={classes.row}>
                      <TextField
                        multiline
                        minRows={2}
                        name={localeKey}
                        id={localeKey}
                        value={newLocale[localeKey]}
                        onChange={handleChange}
                        onBlur={index === 0 ? handleBlur : undefined}
                        placeholder={localeKey}
                        fullWidth
                      />
                    </Grid>
                    <Grid item className={classes.row}>
                      <TextField
                        multiline
                        minRows={2}
                        name={nextLocaleKey}
                        id={nextLocaleKey}
                        value={newLocale[nextLocaleKey]}
                        onChange={handleChange}
                        placeholder={nextLocaleKey}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                );
              } else return null;
            })}
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Fab
                variant="extended"
                onClick={() => onSave()}
                disabled={!valid}
                className={classes.fab}
              >
                Save
              </Fab>
              <Fab
                variant="extended"
                onClick={() => onSave(true)}
                disabled={!valid}
                className={classes.fab}
              >
                Save and close
              </Fab>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
