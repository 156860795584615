import flatten, { unflatten } from "flat";
import { modelExportData, modelLocaleData } from "./helpers";
import { ILocale } from "../interfaces/locale.interface";
import { firebase } from "../firebase";
// @ts-ignore
import * as jsonexport from "jsonexport/dist";
import { INewLocale } from "../reducers/locale.reducer";

const STAGE = 'test';
const googleTranslateKey = 'AIzaSyA0yUm5rLWk-0XKPLg-eqI7jger-P-UJek';

async function loadLocaleData(
  collection: string
): Promise<ILocale> {
  try {
    const fireData = await firebase.db
      .ref(`${STAGE}/${collection}`)
      .once("value", (snap) => snap.val());
    // @ts-ignore
    const resObj: any = fireData.toJSON();

    const localeData = Object.keys(resObj).reduce((acc: any, localeCode) => {
      const { _sheetToFbDate: junk, ...data } = resObj[localeCode];
      acc[localeCode] = flatten(data);
      return acc;
    }, {});

    return localeData;
  } catch (error) {
    return error as any;
  }
}

export async function loadLanguages() {
  const langData = await firebase.db
    .ref('languages')
    .once('value', (snap) => snap.val());

  const json = langData.toJSON();
  return json;
}

export async function loadLocales(collection: string) {
  try {
    const localeData = await loadLocaleData(collection);

    return modelLocaleData(localeData);
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function exportLocaleData(collection: string) {
  try {
    const localeData = await loadLocaleData(collection);

    const csv = await jsonexport(modelExportData(localeData));

    return makeTextFile(csv, collection);
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function updateLocaleData(collection: string, lang: string, data: object, stage?: string) {
  console.log({ collection, lang, data, stage })
  try {
    await firebase.db
      .ref(`${stage ?? STAGE}/${collection}/${lang}`)
      .set(unflatten(data));
    await firebase.db
      .ref(`${stage ?? STAGE}/${collection}/${lang}/_sheetToFbDate`)
      .set(new Date().toISOString());
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function promoteLocale(collection: string, lang: string, stage: string) {
  try {
    const fireData = await firebase.db
      .ref(`${STAGE}/${collection}/${lang}`)
      .once('value', (snap) => snap.val());

    const json = fireData.toJSON();

    //@ts-ignore
    const { _sheetToFbDate, ...locales } = json;
    const flat = flatten(locales) as ILocale;

    return updateLocaleData(collection, lang, flat, stage);
  } catch (error) {
    console.log(error);
  }
}

export async function createLocaleEntry(collection: string, lang: string, childPath: string, data: string) {
  try {
    await firebase.db
      .ref(`${STAGE}/${collection}/${lang}/${childPath}`)
      .set(data);

    await firebase.db
      .ref(`${STAGE}/${collection}/${lang}/_sheetToFbDate`)
      .set(new Date().toISOString());
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function addLocale(collection: string, data: INewLocale) {
  try {
    const { key, ...locales } = data;
    const childPath = key.replace(/\./g, '/');
    Object.keys(locales).forEach((locale) => {
      const hasLocaleData = !!data[locale];
      // Only store locale keys that are not empty strings
      if (hasLocaleData) createLocaleEntry(collection, locale, childPath, data[locale]);
    });
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function getOverrideKeys(collection: string): Promise<string[]> {
  const fireData = await firebase.db
    .ref(`${STAGE}/${collection}/`)
    .once('value', (snap) => snap.val());

  const json = fireData.toJSON();

  return Object.keys(json ?? {});
}

export async function translateLocales(text: string, locales: string[]) {
  const targets = locales.filter(l => l !== 'en');

  return await Promise.all(targets.map((lang: string) => handleTranslation(text, lang)));
}

export async function translateKeys(
  keys: { [key: string]: string },
  lang: string
) {
  const entries = Object.entries(keys)
  const translatedEntries = await Promise.all(entries
    .map(async ([key, text]: [string, string]) => {
      const result = await handleTranslation(text, lang)
      if (result) {
        const { value } = result
        return [key, value]
      }
    })
  )

  return Object.fromEntries(translatedEntries.filter(Boolean) as [[string, string]]);
}

export async function handleTranslation(text: string, locale: string) {
  const baseUrl = 'https://translation.googleapis.com/language/translate/v2';
  try {
    const res = await fetch(`${baseUrl}?key=${googleTranslateKey}&q=${encodeURI(text)}&source=en&target=${locale}`);
    const json = await res.json();

    return {
      name: locale,
      value: json.data.translations[0].translatedText,
    };
  } catch (err) {
    console.log(err);
  }
}

function makeTextFile(text: string, collection: string) {
  const data = new Blob([text], { type: 'text/csv' });

  const downloadLink = document.createElement("a");
  downloadLink.download = `${collection}.csv`;
  downloadLink.innerHTML = "Download File";
  // window.URL.createObjectURL(data);
  downloadLink.href = window.webkitURL.createObjectURL(data);

  downloadLink.click();
}
