import {FormControl, Input, InputLabel, ListItemText, makeStyles, MenuItem, Select} from "@material-ui/core";
import React from "react";

interface IProps {
  entryCodes: string[];
  activeEntryCode: string;
  onChange: (e: any) => void;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 220,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default (props: IProps) => {
  const {entryCodes, activeEntryCode, onChange} = props;

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="language-picker">Override Entry Code</InputLabel>
      <Select
        labelId="language-picker"
        id="language-picker"
        value={activeEntryCode}
        onChange={onChange}
        input={<Input id="select-single-chip"/>}
        MenuProps={MenuProps}
      >
        {entryCodes.map((ec) => (
          <MenuItem key={ec} value={ec}>
            <ListItemText primary={ec.toUpperCase()}/>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}