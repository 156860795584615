import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import LocaleRow from "./locale-row";
import {
  exportLocaleData,
  promoteLocale,
  translateKeys,
  handleTranslation,
  updateLocaleData,
} from "../../api/locale.api";
import {
  filterLocaleData,
  IState,
  localeValueUpdate,
} from "../../reducers/locale.reducer";
import { FixedSizeList as List } from "react-window";
import { useViewport } from "../../context/use-viewport";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { IUser } from "../../reducers/users.reducer";
import LocaleHeaderRow from "./locale-header-row";
import Snackbar from "@material-ui/core/Snackbar";
import FilterRow from "../../components/filter-row";
import { filterEmptyLocaleValues, localeFilter } from "../../api/helpers";
import LocaleActions from "./locale-actions";
import LanguagePicker from "./language-picker";
import { Button, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

interface IProps {
  authUser: IUser;
  data: IState;
  dispatch: any;
  collection: string;
  openModal: () => void;
}

const useStyles = makeStyles(() => ({
  body: {
    padding: "0 20px",
  },
  searchAllEmptyText: {
    fontSize: "9px",
    color: "#fff",
  },
}));

function LocaleComponent(props: IProps) {
  const { authUser, data, dispatch, collection, openModal } = props;
  const canEdit = authUser?.roles?.editor;
  const [serachingForEmptyValues, setSerachingForEmptyValues] = useState(false);

  const classes = useStyles();

  const { filterSource, keys, sourceLocale, locale, changed, languages } = data;
  const { width, height } = useViewport();

  const [snackBar, toggleSnackbar] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(["en"]);

  const selectedLanguage = selectedLanguages.join(", ");

  const onFilter = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    // @ts-ignore
    const { value } = event.target;
    const filtered = localeFilter(sourceLocale, filterSource, value);
    dispatch(filterLocaleData(filtered));
  };

  const onBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    localeKey: string
  ) => {
    event.preventDefault();
    // @ts-ignore
    const {
      target: { name: lang, value },
    } = event;

    dispatch(localeValueUpdate({ lang, value, localeKey }));
  };

  const handleTranslateAll = async (lang: string) => {
    const translations = await translateKeys(locale['en'], lang);

    Object.entries(translations).forEach(([localeKey, value]) => {
      dispatch(localeValueUpdate({ lang, value, localeKey }));
    });
  };

  const handleTranslate = async (text: string, localeKey: string, lang: string) => {
    const result = await handleTranslation(text, lang)

    if (result) {
      const { value } = result
      dispatch(localeValueUpdate({ lang, value, localeKey }))
    }
  }

  const onFilterEmptyValues = () => {
    setSerachingForEmptyValues(true);
    // @ts-ignore
    const filtered = filterEmptyLocaleValues(sourceLocale, selectedLanguages);
    setTimeout(async () => {
      await dispatch(filterLocaleData(filtered));
      setSerachingForEmptyValues(false);
    }, 1000);
  };

  const onSave = async () => {
    changed.forEach((lang: string) =>
      updateLocaleData(collection, lang, sourceLocale[lang])
    );
    toggleSnackbar(true);
  };

  const onPromote = async (stage: string) => {
    // @ts-ignore
    await Promise.all(
      Object.keys(locale).map((lang: string) =>
        promoteLocale(collection, lang, stage)
      )
    );
    toggleSnackbar(true);
  };

  const onExport = () => {
    return exportLocaleData(collection);
  };

  const Row = ({ index, style }: { index: number; style: any }) => {
    const loc = Object.keys(locale).reduce((loc, localeCode) => {
      loc[localeCode] = locale[localeCode][keys[index]];
      return loc;
    }, {} as any);

    return (
      <div style={style}>
        <LocaleRow
          key={filterSource[index]}
          localeKey={keys[index]}
          locale={loc}
          onBlur={onBlur}
          canEdit={canEdit}
          selectedLanguages={selectedLanguages}
          handleTranslate={handleTranslate}
        />
      </div>
    );
  };

  return (
    <Grid container className={classes.body}>
      <Grid container spacing={4} alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <FilterRow onFilter={onFilter} />
        </Grid>
        <Grid item xs={3}>
          <Tooltip
            title={<p>{`search empty translations for ${selectedLanguage}`}</p>}
            arrow
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => onFilterEmptyValues()}
            >
              <SearchIcon />
              {serachingForEmptyValues ? (
                <p className={classes.searchAllEmptyText}>Searching...</p>
              ) : (
                <p className={classes.searchAllEmptyText}>empty translations</p>
              )}
            </Button>
          </Tooltip>
        </Grid>
        {/* <button onClick={() => onFilterEmptyValues()}>Filter</button> */}
        <Grid item>
          <LanguagePicker
            languages={languages}
            selectedLanguages={selectedLanguages}
            setSelectedLanguages={setSelectedLanguages}
          />
        </Grid>
      </Grid>
      <LocaleHeaderRow
        canEdit={canEdit}
        handleTranslateAll={handleTranslateAll}
        selectedLanguages={selectedLanguages}
      />
      <List
        itemCount={keys.length}
        width={width}
        height={height - 250}
        itemSize={50}
      >
        {Row}
      </List>

      <LocaleActions
        authUser={authUser}
        onPromote={onPromote}
        onSave={onSave}
        onToggle={openModal}
        onExport={onExport}
      />

      <Snackbar
        open={snackBar}
        autoHideDuration={6000}
        onClose={() => toggleSnackbar(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        message="Action Completed"
      />
    </Grid>
  );
}

export default LocaleComponent;
