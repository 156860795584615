import React from "react";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface IProps {
  onFilter: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const useStyles = makeStyles(() => ({
  filterBar: {
    margin: '0 -16px 16px -16px',
  }
}));

export default function FilterRow(props: IProps) {
  const classes = useStyles();
  const {onFilter} = props;

  return (
    <Grid container spacing={4} justifyContent="space-evenly" className={classes.filterBar}>
      <Grid item xs={12}>
        <TextField id="locale-key" label="Filter Locale" defaultValue="" fullWidth onChange={onFilter}/>
      </Grid>
    </Grid>
  );
}
