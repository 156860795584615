import Grid from '@material-ui/core/Grid/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import React, {useState} from 'react';
import EntryCodePicker from './entry-code-picker.component';
import AddEntryModal from './add-entry-code.modal';
import Fab from "@material-ui/core/Fab";

interface IProps {
  entryCodes: string[];
  activeEntryCode: string;
  onChange: (event: any) => void;
  onCreate: (ec: string) => void;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 'bold',
  },
  entryCode: {
    textTransform: 'uppercase',
  },
  container: {
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  fab: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

export default (props: IProps) => {
  const {activeEntryCode, entryCodes, onChange, onCreate} = props;

  const classes = useStyles();

  const [isOpen, toggleOpen] = useState<boolean>(false);

  const openModal = () => toggleOpen(true);
  const closeModal = () => toggleOpen(false);

  return (
    <Grid container className={classes.container} alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography>
          <span className={classes.heading}>Active Entry Code: </span>
          <span className={classes.entryCode}>{activeEntryCode}</span>
        </Typography>
      </Grid>
      <Grid item>
        <EntryCodePicker
          entryCodes={entryCodes}
          activeEntryCode={activeEntryCode}
          onChange={onChange}
        />
        <Fab
          variant="extended"
          onClick={() => openModal()}
          className={classes.fab}
        >
          Add New
        </Fab>
        <AddEntryModal
          isOpen={isOpen}
          handleClose={closeModal}
          onCreate={onCreate}
        />
      </Grid>
    </Grid>
  );
}
