export interface IUser {
  id?: string;
  email: string;
  roles: {
    admin: boolean;
    editor: boolean;
    creator: boolean;
    stylist: boolean;
    overrideCreator: boolean;
  };
}

interface IState {
  loading: boolean;
  users: IUser[];
  user: IUser
}

const SET_USERS = 'SET_USERS';
const SET_USER = 'SET_USER';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_ROLES = 'UPDATE_ROLES';
const SET_USER_UPDATED = 'SET_USER_UPDATED';
const SET_NEW_USER = 'SET_NEW_USER';
const REMOVE_DELETED_USER = 'REMOVE_DELETED_USER';

export const setUsers = (data: any) => ({type: SET_USERS, data});
export const setUser = (data: any) => ({type: SET_USER, data});
export const setNewUser = (data: any) => ({type: SET_NEW_USER, data});
export const setUserUpdated = (data: any) => ({type: SET_USER_UPDATED, data});
export const updateUser = (data: any) => ({type: UPDATE_USER, data});
export const updateRoles = (data: any) => ({type: UPDATE_ROLES, data});
export const removeDeletedUser = (data: any) => ({type: REMOVE_DELETED_USER, data});

export const initialState: IState = {
  loading: true,
  users: [],
  user: {
    email: '',
    roles: {
      admin: false,
      editor: false,
      creator: false,
      stylist: false,
      overrideCreator: false,
    }
  },
}

export default (state = initialState, action: any) => {
  const {type, data} = action;
  switch (type) {
    case SET_USERS:
      return {
        ...state,
        users: data,
        loading: false,
      }

    case SET_USER:
      return {
        ...state,
        user: data,
      }

    case SET_NEW_USER:
      return {
        ...state,
        user: initialState.user,
        users: [
          {...data},
          ...state.users,
        ]
      }

    case UPDATE_USER: {
      const {name, value} = data;

      return {
        ...state,
        user: {
          ...state.user,
          [name]: value,
        }
      };
    }
    case UPDATE_ROLES: {
      const {name, checked} = data;

      return {
        ...state,
        user: {
          ...state.user,
          roles: {
            ...state.user.roles,
            [name]: checked,
          }
        }
      };
    }

    case SET_USER_UPDATED: {
      const users = [...state.users];
      const filtered = users.filter(u => u.email !== data.email);

      return {
        ...state,
        user: initialState.user,
        users: [
          {...data},
          ...filtered,
        ],
      }
    }

    case REMOVE_DELETED_USER:
      return {
        ...state,
        users: state.users.filter((usr: IUser) => usr.id !== data),
      }

    default:
      return state;
  }
}
