import * as React from "react";
import {firebase} from "../firebase";
import {AuthUserContext} from "./auth-context";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getFirebaseUsers} from "../api/users.api";
import {IUser} from "../reducers/users.reducer";

export const withAuthentication = (Component: any) => {
  return (props: any) => {
    const navigate = useNavigate();

    const [state, setState] = useState<{ authUser: any, users: string[] }>({authUser: null, users: []});
    const {authUser, users} = state;

    useEffect(() => {
      const load = async () => {
        const users = await getFirebaseUsers() as IUser[];
        setState(s => ({
          ...s,
          users: users.map((usr: IUser) => usr.email),
        }));
      }

      load();
    }, []);

    useEffect(() => {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          // If the logged in user is not in the users array, logout and return home
          if (users.length > 0 && !users.includes(authUser.email ?? '')) {
            firebase.auth.signOut();
            return window.location.href = '/';
          }

          setState(s => ({...s, authUser,}));
        }
      });
    }, [navigate, users]);

    return (
      <AuthUserContext.Provider value={authUser}>
        <Component {...props} authUser={authUser}/>
      </AuthUserContext.Provider>
    );
  }
};
