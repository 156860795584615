import React from 'react';
import {Grid} from "@material-ui/core";
import TranslateIcon from '@material-ui/icons/Translate'
import IconButton from '@material-ui/core/IconButton'
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface IProps {
  selectedLanguages: string[];
  canEdit?: boolean;
  handleTranslateAll: (lang: string) => void;
}

const useStyles = makeStyles(() => ({
  headerBar: {
    margin: '0 -16px 16px -16px',
  }
}));

/* const languagesMap: { [k: string]: string } = {
  en: 'English (EN)',
  es: 'Spanish (ES)',
  pt: 'Portuguese (PT)',
  nl: 'Dutch (NL)',
} */

export default function LocaleHeaderRow(props: IProps) {
  const {selectedLanguages, canEdit, handleTranslateAll} = props;

  const classes = useStyles();

  return (
    <Grid container spacing={4} className={classes.headerBar}>
      <Grid item xs={3}>
        <Typography>Locale Path</Typography>
      </Grid>
      {selectedLanguages.map((lang) => {
        const showTranslateButton = canEdit && lang !== 'en';

        return (
          <React.Fragment key={lang}>
            <Grid item xs={showTranslateButton ? 1 : 3} key={lang}>
              {/* <Typography>{languagesMap[lang]}</Typography> */}
              <Typography>{lang.toUpperCase()}</Typography>
            </Grid>
            {showTranslateButton &&
              <Grid item xs={2}>
                <IconButton onClick={() => handleTranslateAll(lang)} >
                  <TranslateIcon/>
                </IconButton>
              </Grid>
            }
          </React.Fragment>
        )
      })}
    </Grid>
  );
}
