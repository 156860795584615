import React, {useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Grid,} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import {modalStyles} from '../../../styles/modal.styles';


interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  onCreate: (ec: string) => void;
}

export default function AddEntryModal(props: IProps) {
  const {isOpen, handleClose, onCreate} = props;

  const classes = modalStyles();

  const [entryCode, setEntryCode] = useState<string>('');


  const handleChange = (e: any) => setEntryCode(e.target.value);

  const onSave = async () => {
    onCreate(entryCode);
    handleChange('');
    handleClose();
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <Grid container direction="row" justifyContent="space-between" className={classes.header}>
            <Grid item>
              <h2 id="transition-modal-title">Create new locale entry</h2>
              <p>
                <b>Note:</b> This entry code will only be persisted to the DB once at least one override exists
              </p>
            </Grid>
          </Grid>

          <Grid item className={classes.row}>
            <TextField
              name="key"
              value={entryCode}
              onChange={handleChange}
              placeholder="New Entry Code"
              fullWidth/>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Fab
                variant="extended"
                onClick={() => onSave()}
                className={classes.fab}
              >
                Save
              </Fab>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}
