import {ILocale} from "../interfaces/locale.interface";

export interface INewLocale {
  key: string;
  [k: string]: string;
}

export interface IState {
  filterSource: string[];
  sourceLocale: ILocale;
  locale: ILocale;
  keys: string[];
  changed: string[];
  newLocale: INewLocale;
  languages: string[];
}

const PREP_LOCALE_DATA = 'PREP_LOCALE_DATA';
const FILTER_DATA = 'FILTER_DATA';
const LOCALE_VALUE_UPDATE = 'LOCALE_VALUE_UPDATE';
const SET_CREATE_DATA = 'SET_CREATE_DATA';
const NEW_LOCALE_SAVED = 'NEW_LOCALE_SAVED';
export const prepLocaleData = (data: any) => ({type: PREP_LOCALE_DATA, data});
export const filterLocaleData = (data: any) => ({type: FILTER_DATA, data});
export const localeValueUpdate = (data: any) => ({type: LOCALE_VALUE_UPDATE, data});
export const setCreateData = (data: any) => ({type: SET_CREATE_DATA, data});
export const newLocaleSaved = (data: any) => ({type: NEW_LOCALE_SAVED, data});

export const initialState: IState = {
  filterSource: [],
  sourceLocale: {},
  locale: {},
  keys: [],
  changed: [],
  newLocale: {
    key: "",
  },
  languages: []
};

export default function localeReducer(
  state = initialState,
  action: { type: String; data: any }
): IState {
  const { type, data } = action;
  switch (type) {
    case PREP_LOCALE_DATA:
      return {
        ...state,
        filterSource: data.filters,
        sourceLocale: {
          ...data.locales,
        },
        locale: {
          ...data.locales,
        },
        keys: data.keys,
        languages: data.languages,
      };

    case FILTER_DATA:
      return {
        ...state,
        locale: data.locale,
        keys: data.keys,
      };

    case LOCALE_VALUE_UPDATE: {
      const { lang, value, localeKey } = data;

      return {
        ...state,
        locale: {
          ...state.locale,
          [lang]: {
            // @ts-ignore
            ...state.locale[lang],
            [localeKey]: value,
          },
        },
        sourceLocale: {
          ...state.sourceLocale,
          [lang]: {
            // @ts-ignore
            ...state.sourceLocale[lang],
            [localeKey]: value,
          },
        },
        changed: [...state.changed, lang],
      };
    }

    case SET_CREATE_DATA:
      return {
        ...state,
        newLocale: {
          ...state.newLocale,
          [data.name]: data.value,
        },
      };

    case NEW_LOCALE_SAVED:
      const { key, ...rest } = data;

      return {
        ...state,
        filterSource: [
          ...state.filterSource,
          Object.keys(rest).reduce((filterText, localeCode) => {
            return filterText + " " + rest[localeCode];
          }, `${key}`),
        ],
        keys: [key, ...state.keys],
        locale: {
          ...Object.keys(state.locale).reduce((result, localeCode) => {
            result[localeCode] = {
              ...state.locale[localeCode],
              [key]: rest[localeCode],
            };
            return result;
          }, {} as any),
        },
        sourceLocale: {
          ...Object.keys(state.sourceLocale).reduce((result, localeCode) => {
            result[localeCode] = {
              ...state.sourceLocale[localeCode],
              [key]: rest[localeCode],
            };
            return result;
          }, {} as any),
        },
        newLocale: initialState.newLocale,
      };

    default:
      return initialState;
  }
}
