import {firebase} from "../firebase";
import {IUser} from "../reducers/users.reducer";

export async function getFirebaseUsers() {
  try {
    const fireData = await firebase.db
      .ref('stream-users')
      .once('value', (snap) => snap.val());

    const json = fireData.toJSON();
    // @ts-ignore
    return Object.keys(json)
      .map((k: string) => ({
        id: k,
        //@ts-ignore
        ...json[k],
      }));
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function addNewFirebaseUser(data: IUser) {
  try {
    const {id, ...rest} = data;

    const response = await firebase.db
      .ref('stream-users')
      .push(rest)

    return response.key
  } catch (error) {
    console.error('error', error);
    return error;
  }
}

export async function updateFirebaseUser(data: IUser) {
  try {
    const {id, ...rest} = data;

    await firebase.db
      .ref(`stream-users/${id}`)
      .set(rest);
  } catch (error) {
    console.error('error', error);
    return error;
  }
}


export async function deleteFirebaseUser(data: IUser) {
  try {
    const {id} = data;

    await firebase.db
      .ref(`stream-users/${id}`)
      .remove();
  } catch (error) {
    console.error('error', error);
    return error;
  }
}
