import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {routes} from "../core/routes";
import {firebase} from "../firebase";
import {AuthUserContext} from "./auth-context";
import {getFirebaseUsers} from "../api/users.api";
import {IUser} from "../reducers/users.reducer";

export const withAuthorization = (condition: any) => (Component: any) => {
  return () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();

    useEffect(() => {
      const load = async () => {
        const users = await getFirebaseUsers();
        // @ts-ignore
        setUsers(users);
      }

      load();
    }, [])

    useEffect(() => {
      firebase.auth.onAuthStateChanged(authUser => {
        if (users.length > 0) {
          //@ts-ignore
          const me = users.find((usr: IUser) => usr.email === authUser?.toJSON().email)
          if (me) setUser(me);
        }
        if (!condition(authUser)) navigate(routes.home);
      });
    }, [navigate, users]);

    return (
      <AuthUserContext.Consumer>
        {authUser => (authUser ? <Component authUser={user}/> : null)}
      </AuthUserContext.Consumer>
    )
  };
};
