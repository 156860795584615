import { makeStyles, FormControl, InputLabel, Select, Input, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import React from "react";

interface IProps {
  languages: string[];
  selectedLanguages: string[];
  setSelectedLanguages: React.Dispatch<React.SetStateAction<string[]>>;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 220,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function LanguagePicker(props: IProps) {
  const { languages, selectedLanguages, setSelectedLanguages } = props;

  const classes = useStyles();

  const handleChange = (event: any) => {
    const {value} = event.target;
    const updated = [...value.splice(0, 3)];

    setSelectedLanguages(updated);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="language-picker">Displayed Languages</InputLabel>
      <Select
        labelId="language-picker"
        id="language-picker"
        multiple
        value={selectedLanguages}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          //@ts-ignore
          selected.join(', ')
        )}
        MenuProps={MenuProps}
      >
        {languages.map((lang) => (
          <MenuItem key={lang} value={lang}>
            <Checkbox checked={selectedLanguages.includes(lang)} />
            <ListItemText primary={lang} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default LanguagePicker;