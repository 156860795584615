import flatten, {unflatten} from "flat";
import {firebase} from "../firebase";

const STAGE = 'test';
const BASE_COLLECTION = 'member-ui-flutter-themes';

function deepMerge(parent: Object | null = {}, child: Object | null = {}) {
  const fp = flatten(parent);
  const fc = flatten(child);
  const merged = {
  // @ts-ignore
    ...fp,
  // @ts-ignore
    ...fc,
  };

  return unflatten(merged);
}

export async function loadThemeData(collection: string): Promise<any> {
  try {
    const masterData = await firebase.db
      .ref(`${STAGE}/${BASE_COLLECTION}/master/`)
      .once('value', (snap) => snap.val());

    const fireData = await firebase.db
      .ref(`${STAGE}/member-ui-flutter-themes/${collection}/`)
      .once('value', (snap) => snap.val());

    const masterJson = masterData.toJSON();
    const json = fireData.toJSON();

    const data = deepMerge(masterJson, json);

    //@ts-ignore
    const {__lastUpdated, ...rest} = data;

    return rest;
  } catch (error) {
    return error;
  }
}

export async function updateThemeData(collection: string, data: Object, stage?: string) {
  try {
    if (!Object.keys(data).length) return;

    await firebase.db
      .ref(`${stage ?? STAGE}/${BASE_COLLECTION}/${collection}/`)
      .set(unflatten(data));

    await firebase.db
      .ref(`${stage ?? STAGE}/${BASE_COLLECTION}/${collection}/_sheetToFbDate`)
      .set(new Date().toISOString());
  } catch (error) {
    console.error('error', error);
    return error;
  }
}
