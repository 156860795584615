import React, {useEffect, useReducer, useState} from "react";
import {useParams} from "react-router-dom";
import {getOverrideKeys, loadLocales, loadLanguages} from "../../api/locale.api";
import {withAuthorization} from "../../firebase/with-authorization";
import {IUser} from '../../reducers/users.reducer';
import reducer, {initialState, prepLocaleData} from '../../reducers/locale.reducer';
import LocaleComponent from "../components/locale";
import LocaleCreateModal from "../components/locale-create-modal";
import OverridesHeader from './components/overrides.header';

interface IProps {
  authUser: IUser;
}

function OverridesComponent(props: IProps) {
  const {authUser} = props;

  const [data, dispatch] = useReducer(reducer, initialState);
  const [languages, setLanguages] = useState<string[]>(initialState.languages)
  const [isOpen, toggleOpen] = useState<boolean>(false);

  const openModal = () => toggleOpen(true);
  const closeModal = () => toggleOpen(false);

  const [activeEntryCode, setActiveEntryCode] = useState<string>();
  const [entryCodes, setEntryCodes] = useState<string[]>([]);
  const params = useParams();
  const collection = params.collection as string;

  useEffect(() => {
    async function load(){
      const allLanguages: any = await loadLanguages();
      const languages: string[] = Object.keys(allLanguages) || [];
      setLanguages(languages)
    }

    load();
  }, [])

  useEffect(() => {
    async function load() {
      const keys: string[] = await getOverrideKeys(collection);
      const ec = keys[0];
      setEntryCodes(keys);
      setActiveEntryCode(ec);
      // fetch the local data for the active tab
      const localeData: any = await loadLocales(`${collection}/${ec}`);
      dispatch(
        prepLocaleData({
          ...localeData,
          languages,
        })
      );
    }

    load();
  }, [collection, languages]);

  useEffect(() => {
    async function load() {
      // fetch the local data for the active tab
      const localeData = await loadLocales(`${collection}/${activeEntryCode}`);
      dispatch(
        prepLocaleData({
          // @ts-ignore
          ...localeData,
          languages,
        })
      );
    }

    load();
  }, [activeEntryCode, collection, languages]);

  const handleChange = (event: any) => {
    const {value} = event.target;

    setActiveEntryCode(value);
  };

  const createEntryCode = (ec: string) => {
    // Add new entry code to existing list of codes
    if (!entryCodes.includes(ec)) setEntryCodes([...entryCodes, ec].sort());
    // Activate the new entry code
    setActiveEntryCode(ec);
  }

  return (
    <>
      {!!activeEntryCode &&
      <>
          <OverridesHeader
              entryCodes={entryCodes}
              activeEntryCode={activeEntryCode}
              onChange={handleChange}
              onCreate={createEntryCode}
          />
          <LocaleComponent
              authUser={authUser}
              data={data}
              dispatch={dispatch}
              collection={`${collection}/${activeEntryCode}`}
              openModal={openModal}
          />
      </>
      }

      <LocaleCreateModal
        handleClose={closeModal}
        isOpen={isOpen}
        collection={`${collection}/${activeEntryCode}`}
        activeEntryCode={activeEntryCode}
        data={data}
        dispatch={dispatch}
      />
    </>
  );
}

const authCondition = (authUser: any) => !!authUser;

export default withAuthorization(authCondition)(OverridesComponent);
