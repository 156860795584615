import * as firebase from 'firebase/app';
import { auth } from "./firebase";

export const googleSignIn = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
}

export const googleSignOut = () => {
  auth.signOut();
  window.location.pathname = "/";
}

export const isAuthed = () => !!auth.currentUser;

